<script lang="ts" setup>
import { useGlobalStore } from '@/stores'
const globalStore = useGlobalStore()
</script>

<template>
  <div class="social" :class="{ dark: globalStore.isDark }">
    <nuxt-link to="https://www.facebook.com/hengstyle.tw/" title="facebook" target="_blank">
      <svg-icon name="icon-fb" />
    </nuxt-link>
    <nuxt-link to="https://www.instagram.com/hengstyle.hlh/" title="instagram" target="_blank">
      <svg-icon name="icon-ig" />
    </nuxt-link>
    <nuxt-link to="https://page.line.me/wxq1916l?openQrModal=true" title="LINE" target="_blank">
      <svg-icon name="icon-line" />
    </nuxt-link>
    <nuxt-link to="https://www.youtube.com/channel/UC2owg51JJdX2MI6eUtOOuCA" title="youtube" target="_blank">
      <svg-icon name="icon-youtube" />
    </nuxt-link>
  </div>
</template>

<style lang="scss" scoped>
.social {
  display: flex;
  align-items: center;

  &.dark {
    a {
      color: $color-white-1;
    }
  }

  a {
    width: toRem(24);
    height: toRem(24);
    margin-right: toRem(20);
  }

  svg {
    display: block;
    width: 100%;
  }
}
</style>
